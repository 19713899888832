<template>
  <div>
    <b-list-group>
      <div style="overflow-y: scroll; max-height: 200px">
        <b-list-group-item
          v-for="(fav, idx) in favorites"
          v-bind:key="idx"
          @click="selectFavorite(fav)"
        >
          <span
            class="text-danger"
            @click="deleteFavorite(fav)"
            title="Click to delete favorite"
            style="cursor: pointer"
          >
            <i class="fas fa-trash-alt"></i>
          </span>
          <span style="cursor: pointer">
            {{ fav.address }}
          </span>
        </b-list-group-item>
      </div>
      <div class="mt-3 text-center" v-if="favorites.length > 0">
        <button class="btn btn-sm btn-outline-danger" @click="deleteFavorites">
          Delete Favorites
        </button>
      </div>
    </b-list-group>
  </div>
</template>

<script>
export default {
  computed: {
    favorites() {
      return this.$store.getters.favorites;
    },
  },
  methods: {
    deleteFavorites() {
      this.$swal({
        title: "Confirm delete your favorites?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteFavorites");
        }
      });
    },
    selectFavorite(favorite) {
      this.$store.dispatch("setLatLng", {
        lng: favorite.lng,
        lat: favorite.lat,
      });
    },
    deleteFavorite(favorite) {
      this.$swal({
        title: "Confirm delete this favorite?",
        text: favorite.address,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteFavorite", favorite);
        }
      });
    },
  },
};
</script>
