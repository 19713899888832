<template>
  <div class="mb-3">
    <label>
      Longitude
      <span class="text-muted">X</span>
    </label>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="Longitude"
        :value="markerPosition.lng"
      />
      <button
        class="btn btn-outline-secondary"
        v-clipboard="() => markerPosition.lng"
        v-clipboard:success="clipboardSuccessHandler"
      >
        <span class="icon">
          <i class="far fa-copy"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    markerPosition() {
      return this.$store.getters.latLng;
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$toasted.show("Copied to clipboard.");
    },
  },
};
</script>
