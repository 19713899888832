<template>
  <div>
    <div class="mb-3">
      <div class="input-group">
        <label
          for="format"
          class="input-group-text"
          title="Use {x} to Longitude (X) and {y} to Latitude (Y) values."
          style="cursor: help"
          >Format</label
        >
        <input
          id="format"
          type="text"
          class="form-control"
          placeholder="Custom format"
          v-model="customFormat"
        />
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Formats
        </button>
        <ul class="dropdown-menu">
          <li v-for="frm in formats" :key="frm">
            <a class="dropdown-item" href="#" v-on:click="selectFormat(frm)">{{
              frm
            }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="mb-3">
      <label for="custom">Formated value</label>
      <div class="input-group">
        <input
          readonly
          id="custom"
          type="text"
          class="form-control"
          placeholder="Formated value"
          :value="customValue"
        />
        <button
          class="btn btn-outline-secondary"
          v-clipboard="() => customValue"
          v-clipboard:success="clipboardSuccessHandler"
        >
          <span class="icon">
            <i class="far fa-copy"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      customFormat: "POINT({x} {y})",
      formats: [
        "POINT({x} {y})",
        "Point({x}, {y})",
        "({x}, {y})",
        "({y}, {x})",
        "[{x}, {y}]",
        '{"x": {x}, "y": {y}}',
        '{"lng": {x}, "lat": {y}}',
      ],
    };
  },
  computed: {
    markerPosition() {
      return this.$store.getters.latLng;
    },
    customValue() {
      return this.customFormat
        .replaceAll("{x}", this.markerPosition.lng)
        .replaceAll("{y}", this.markerPosition.lat);
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$toasted.show("Copied to clipboard.");
    },
    selectFormat(format) {
      this.customFormat = format;
    },
  },
};
</script>
