<template>
  <div class="my-2 my-lg-0">
    <b-button variant="light" v-b-modal.modal-about>About</b-button>

    <b-modal id="modal-about" centered hide-footer title="About getLonLat">
      <link-referral class="mb-5" />
      <made-with-love by="Éverton Inocêncio" link="https://hewerthomn.com" />
    </b-modal>
  </div>
</template>

<script>
import LinkReferral from "./LinkReferral.vue";

export default {
  components: {
    linkReferral: LinkReferral,
  },
};
</script>
