<template>
  <div>
    <app-header class="mb-3"></app-header>
    <div class="container-fluid">
      <app-map></app-map>
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppMap from "./components/AppMap.vue";

export default {
  metaInfo: {
    title: "getLonLat",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { charset: "utf-8" },
      {
        property: "og:title",
        content: "Get coordinates (longitute, latitude) from map",
        vmid: "og:title",
      },
    ],
  },
  components: {
    appHeader: AppHeader,
    appMap: AppMap,
  },
};
</script>
