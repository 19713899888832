<template>
  <div class="mb-3">
    <div class="input-group">
      <label for="hash" class="input-group-text">
        <a href="https://en.wikipedia.org/wiki/Geohash" target="_blank">
          <abbr
            title="Geohash is a public domain geocode system invented in 2008 by Gustavo Niemeyer[1] which encodes a geographic location into a short string of letters and digits."
          >
            Geohash
          </abbr>
        </a>
      </label>
      <input
        id="hash"
        type="text"
        class="form-control"
        placeholder="Geohash"
        :value="hash"
      />
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Precision
      </button>
      <ul class="dropdown-menu">
        <li v-for="idx in [...Array(12).keys()]" :key="idx">
          <a
            class="dropdown-item"
            :class="precision == idx ? 'active' : ''"
            href="#"
            v-on:click="precision = idx"
          >
            {{ idx }}
          </a>
        </li>
      </ul>
      <button
        class="btn btn-outline-secondary"
        v-clipboard="() => hash"
        v-clipboard:success="clipboardSuccessHandler"
      >
        <span class="icon">
          <i class="far fa-copy"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
var geohash = require("ngeohash");

export default {
  data() {
    return {
      precision: 9,
    };
  },
  computed: {
    markerPosition() {
      return this.$store.getters.latLng;
    },
    hash() {
      return geohash.encode(
        this.markerPosition.lat,
        this.markerPosition.lng,
        this.precision,
      );
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$toasted.show("Copied to clipboard.");
    },
  },
};
</script>
