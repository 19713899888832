<template xmlns:v-clipboard="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="col-md-6 col-lg-5">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <app-longitude></app-longitude>
            </div>
            <div class="col-lg-6">
              <app-latitude></app-latitude>
            </div>
          </div>

          <app-geohash />
          <custom-format />

          <div class="mb-3">
            <div class="btn-group" role="group" aria-label="Toolbar">
              <use-gps />

              <button
                class="btn btn-sm btn-outline-secondary"
                @click="dropMarker"
                title="Click to center the marker on the map"
              >
                <i class="fas fa-map-marked-alt"></i>
                CENTER MARKER
              </button>
            </div>
            <app-favorite />
          </div>
        </div>
      </div>

      <b-nav tabs class="mb-3">
        <b-nav-item
          v-if="hasFavorites"
          @click="activeTab = 'favorites'"
          :active="activeTab == 'favorites'"
        >
          Favorites
        </b-nav-item>
        <b-nav-item
          v-if="hasHistory"
          @click="activeTab = 'searchHistory'"
          :active="activeTab == 'searchHistory'"
        >
          Search history
        </b-nav-item>
      </b-nav>

      <app-favorites v-if="activeTab === 'favorites'"></app-favorites>
      <app-history v-if="activeTab === 'searchHistory'"></app-history>
    </div>
    <div class="col-md-6 col-lg-7">
      <div class="card">
        <search-address></search-address>
        <div class="card-image">
          <gmap-map ref="gmap" class="map" :center="mapCenter" :zoom="zoom">
            <gmap-marker
              :position="markerPosition"
              :clickable="true"
              :draggable="true"
              @dragend="dragEnd"
            />
          </gmap-map>
        </div>
        <div class="card-body">
          <app-address></app-address>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLongitude from "./AppLongitude.vue";
import AppLatitude from "./AppLatitude.vue";
import AppGeoHash from "./AppGeohash.vue";
import AppAddress from "./AppAddress.vue";
import SearchAddress from "./SearchAddress.vue";
import CustomFormat from "./CustomFormat.vue";
import UseGps from "./UseGps.vue";
import AppHistory from "./AppHistory.vue";
import AppFavorites from "./AppFavorites.vue";
import AppFavorite from "./AppFavorite.vue";

export default {
  data: function () {
    return {
      map: null,
      activeTab: "",
    };
  },
  computed: {
    hasFavorites() {
      return this.$store.getters.favorites.length > 0;
    },
    hasHistory() {
      return this.$store.getters.addressHistory.length > 0;
    },
    zoom() {
      return this.$store.getters.zoom;
    },
    mapCenter() {
      return this.$store.getters.latLng;
    },
    markerPosition() {
      return this.$store.getters.latLng;
    },
  },
  methods: {
    dropMarker() {
      if (!this.map) {
        return;
      }

      const center = this.map.getCenter();
      const latLng = {
        lat: center.lat(),
        lng: center.lng(),
      };
      this.$store.dispatch("setLatLng", latLng);
    },
    dragEnd(evt) {
      const latLng = { lat: evt.latLng.lat(), lng: evt.latLng.lng() };
      this.$store.dispatch("setLatLng", latLng);
    },
  },
  mounted() {
    var self = this;
    self.$refs.gmap.$mapPromise.then(() => {
      self.map = self.$refs.gmap.$mapObject;
    });
  },
  components: {
    appLongitude: AppLongitude,
    appLatitude: AppLatitude,
    appGeohash: AppGeoHash,
    searchAddress: SearchAddress,
    AppAddress: AppAddress,
    customFormat: CustomFormat,
    useGps: UseGps,
    appHistory: AppHistory,
    appFavorites: AppFavorites,
    appFavorite: AppFavorite,
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 640px;
}

@media (max-width: 600px) {
  .map {
    height: 250px;
  }
}
</style>
