<template>
  <div class="mb-3" v-if="address">
    <div class="input-group">
      <input type="text" class="form-control" v-model="address" />
      <button
        class="btn btn-outline-secondary"
        v-clipboard="() => address"
        v-clipboard:success="clipboardSuccessHandler"
      >
        <span class="icon">
          <i class="far fa-copy"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    address() {
      return this.$store.getters.address;
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$toasted.show("Copied address to clipboard.");
    },
  },
};
</script>
