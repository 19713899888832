<template>
  <button
    class="btn btn-sm float-end"
    :class="favoriteClass"
    @click="favorite"
    title="Click to favorite (or unfavorite) the actual location"
  >
    <i class="far fa-star"></i>
    FAVORITE
  </button>
</template>

<script>
export default {
  computed: {
    favoriteClass() {
      return this.isFavorite ? "btn-warning" : "btn-outline-warning";
    },
    isFavorite() {
      return (
        this.$store.getters.favorites.filter((fav) => {
          return (
            fav.lat === this.markerPosition.lat &&
            fav.lng === this.markerPosition.lng
          );
        }).lenght > 0
      );
    },
    markerPosition() {
      return this.$store.getters.latLng;
    },
  },
  methods: {
    favorite() {
      let favorite = {
        lat: this.markerPosition.lat,
        lng: this.markerPosition.lng,
        address: this.$store.getters.address,
      };
      this.$store.dispatch("addFavorite", favorite);
    },
  },
};
</script>
