<template>
  <button
    :disabled="showSpinner"
    class="btn btn-sm btn-outline-primary"
    @click="updatePosition"
    title="Click to get coordinates from geolocation (GPS or network location)"
  >
    <i class="fas fa-location-arrow"></i>
    <span v-if="showSpinner"> GEOLOCATING... </span>
    <span v-if="!showSpinner"> USE GPS </span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      showSpinner: false,
    };
  },
  methods: {
    updatePosition() {
      this.showSpinner = true;
      navigator.geolocation.getCurrentPosition((position) => {
        const latLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.$store.dispatch("setLatLng", latLng);
        this.$store.dispatch("setZoom", 15);
        this.showSpinner = false;
      });
    },
  },
};
</script>
