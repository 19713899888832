import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import axios from "axios";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "getlonlat-vue",
  storage: localStorage,
});

const state = {
  zoom: 4,
  address: null,
  latLng: {
    lat: -8,
    lng: -55,
  },
  addresses: [],
  favorites: [],
};

const getters = {
  zoom: (state) => {
    return state.zoom;
  },
  address: (state) => {
    return state.address;
  },
  latLng: (state) => {
    return state.latLng;
  },
  addressHistory: (state) => {
    return state.addresses;
  },
  favorites: (state) => {
    return state.favorites;
  },
};

const mutations = {
  SET_ZOOM(state, zoom) {
    state.zoom = zoom;
  },
  SET_ADDRESS(state, address) {
    state.address = address;
  },
  SET_LATLNG(state, latLng) {
    state.latLng = latLng;
  },
  ADD_SEARCH_TO_HISTORY(state, result) {
    state.addresses.push(result);
  },
  DELETE_FROM_HISTORY(state, address) {
    state.addresses = state.addresses.filter((addrezz) => {
      return addrezz.place_id != address.place_id;
    });
  },
  CLEAR_SEARCH_HISTORY(state) {
    state.addresses = [];
  },
  ADD_FAVORITE(state, favorite) {
    let exists = state.favorites.filter((fav) => {
      return fav.lat === favorite.lat && fav.lng === favorite.lng;
    });
    if (exists.length === 0) {
      state.favorites.push(favorite);
    }
  },
  DELETE_FAVORITE(state, favorite) {
    state.favorites = state.favorites.filter((fav) => {
      return !(fav.lat === favorite.lat && fav.lng === favorite.lng);
    });
  },
  DELETE_FAVORITES(state) {
    state.favorites = [];
  },
};

const actions = {
  setZoom({ commit }, zoom) {
    commit("SET_ZOOM", zoom);
  },
  setLatLng({ commit }, latLng) {
    commit("SET_LATLNG", latLng);

    const params = {
      latlng: `${latLng.lat},${latLng.lng}`,
    };
    let urlGeocoder =
      "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-121f8b33-9d4d-4762-9273-45a7072d545d/Geocoder/GetAddress";
    axios
      .get(urlGeocoder, {
        params,
      })
      .then((result) => {
        if (result.data.results[0]) {
          const address = result.data.results[0].formatted_address;
          commit("SET_ADDRESS", address);
        }
      });
  },
  addToHistory({ commit }, searchResult) {
    commit("ADD_SEARCH_TO_HISTORY", searchResult);
  },
  deleteFromHistory({ commit }, address) {
    commit("DELETE_FROM_HISTORY", address);
  },
  clearHistory({ commit }) {
    commit("CLEAR_SEARCH_HISTORY");
  },
  addFavorite({ commit }, favorite) {
    commit("ADD_FAVORITE", favorite);
  },
  deleteFavorite({ commit }, favorite) {
    commit("DELETE_FAVORITE", favorite);
  },
  deleteFavorites({ commit }) {
    commit("DELETE_FAVORITES");
  },
};

export const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [vuexPersist.plugin],
});
