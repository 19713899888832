<template>
  <div class="mb-3">
    <label for="latitude"> Latitude <span class="text-muted">Y</span> </label>
    <div class="input-group">
      <input
        id="latitude"
        type="text"
        class="form-control"
        placeholder="Latitude"
        :value="markerPosition.lat"
      />
      <button
        class="btn btn-outline-secondary"
        v-clipboard="() => markerPosition.lat"
        v-clipboard:success="clipboardSuccessHandler"
        title="Click to copy the latitude (y)"
      >
        <span class="icon">
          <i class="far fa-copy"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    markerPosition() {
      return this.$store.getters.latLng;
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$toasted.show("Copied latitude to clipboard.");
    },
  },
};
</script>
