import Vue from "vue";
import VueMeta from "vue-meta";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import Clipboard from "v-clipboard";
import Toasted from "vue-toasted";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-made-with-love";

import { store } from "./store";

import * as VueGoogleMaps from "vue2-google-maps";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-made-with-love/dist/vue-made-with-love.css";

Vue.config.productionTip = process.env.NODE_ENV != "production";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);
Vue.use(Clipboard);
Vue.use(Toasted, {
  position: "top-center",
  duration: 3000,
  singleton: true,
});
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
  },
});

new Vue({
  store,
  render: (h) => h(App),
  filters: {
    json: (value) => {
      return JSON.stringify(value, null, 2);
    },
  },
}).$mount("#app");
