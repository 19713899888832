<template>
  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img
          src="../assets/img/logo.png"
          alt=""
          width="30"
          height="30"
          class="d-inline-block align-text-top"
        />
        getLonLat<small class="text-muted">.com</small>
      </a>
      <span class="navbar-nav mr-auto"> &nbsp; </span>
      <app-about />
    </div>
  </nav>
</template>

<script>
import AppAbout from "./AppAbout.vue";

export default {
  components: {
    appAbout: AppAbout,
  },
};
</script>
